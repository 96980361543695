import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object

  ) {
  }

  updateMetaTags(title: string, description: string, keywords?: string, image?: string) {
    const fullUrl = this.getFullUrl();

    this.titleService.setTitle(title);

    this.metaService.updateTag({name: 'description', content: description});
    if (keywords) {
      this.metaService.updateTag({name: 'keywords', content: keywords});
    }
    this.metaService.updateTag({property: 'og:title', content: title});
    this.metaService.updateTag({property: 'og:description', content: description});
    this.metaService.updateTag({property: 'og:url', content: fullUrl});

    if (image) {
      this.metaService.updateTag({property: 'og:image', content: image});
    }


    this.updateCanonicalUrl(fullUrl);

  }

  setToDefault() {
    this.updateMetaTags('Gri Koç Navigasyon Sistemi', 'Gri Koç Navigasyon Sistemi');
    this.updateCanonicalUrl(this.getFullUrl());
  }

  updateCanonicalUrl(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      let link = document.querySelector("link[rel='canonical']") as HTMLLinkElement;

      if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
      }

      link.setAttribute('href', url);
    } else {
      this.metaService.updateTag({ name: 'canonical', content: url });
    }
  }

  /**
   * Returns the full absolute URL of the current route safely (works with SSR).
   */
  private getFullUrl(): string {
    if (isPlatformBrowser(this.platformId)) {
      return `${window.location.origin}${this.router.url}`;
    }
    return `https://grikocnavigasyon.com${this.router.url}`; // Replace with your actual domain
  }
}
